async function generateTable(cmsData) {
  const { tenants } = cmsData

  let tenantsObj = {}

  tenants.forEach(tenant => {
    const { _id, name = '' } = tenant
    const parsedName = name.toLowerCase().replace(' ','-')
    const clientUrl = 'https://jacksonsquare.101staging.com'

    // @TODO: handle special cases (e.g. elevators?) here
    
    let tenantObj = JSON.parse(JSON.stringify(tenant))
    tenantObj.link = `${clientUrl}/tenant/${parsedName}/${_id}/` // set url link
    tenantObj.floor =  tenantObj.floor || { name: 'Floor 1' } // set default floor

    tenantsObj[_id] = tenantObj
  })

  return tenantsObj
}

export default generateTable