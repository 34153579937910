import React, { useState, useEffect, Fragment } from 'react';
import { Map3D } from '@yourhere/yourhere-core'
import { Config, SceneWrapper } from '@yourhere/yourhere-3dmapping'
import generateTable from './generateTable.js'
import './App.scss';
import config from './jacksonsquare-config.json'

import './fonts/BebasNeue.otf'
import './fonts/Roboto/Roboto-Black.ttf'

function App() {
  const [activeFloor, setActiveFloor] = useState('GroundLevel')
  const [activeTenant, setActiveTenant] = useState('')
  const [lookup, setLookup] = useState()
  const [searchTerm, setSearchTerm] = useState('')
  const [cmsData, setCmsData] = useState()
  const [showList, setShowList] = useState(false)
  const [viewMode, setViewMode] = useState('full')
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  
  const changeTenant = (cmsID) => {
    const matchingTenant = cmsData.modTenants.find(t => {
      const {
        locationid = null,
        suite: {
          textfields: {
            suitenum = null
          } = {}
        } = {}
      } = t
      const refID = locationid ? locationid : suitenum
      if (!refID) return
      return `${refID}`.toLowerCase() === `${cmsID}`.toLowerCase()
    })

    if (!matchingTenant) {
      return
    }

    const floorLookup = { 'Floor 1': 'GroundLevel' }
    const floorName = matchingTenant.floor.name
    const modActiveFloor = floorLookup[floorName]
      ? floorLookup[floorName] 
      : floorName.replace(' ','')
    
    setActiveFloor(modActiveFloor)
    setActiveTenant(`${cmsID}`)

    // clear search term if value other than query is selected
    setSearchTerm(searchTerm && matchingTenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ? matchingTenant.name : '')

    if (isMobile) {
      setSearchTerm(matchingTenant.name)
    }
  }

  const changeFloor = (floor) => {
    setShowList(false)
    setActiveTenant('')
    setActiveFloor(floor)
  }

  const focusSearch = () => {
    setShowList(true)
  }

  useEffect(() => {
    const getTenantData = async () => {
      const baseURL = 'https://cms-v2.yourhere.ca'
      const accountid = '62e014d0259243293c7f38f1'
      const key = 'eWOH7yngUDtWNeTlAJj-YnoP'
      // const baseURL = 'http://localhost:80'
      // const accountid = '66fabdc1cf6b8367bc5d34e5'
      // const key = 'ml5-Fd_ly.+CR3gVBd1cetcG'
    
      const cmsRes = await fetch(`${baseURL}/v1/mapdata/tenants/${accountid}/retail`, {
        method: 'GET',
        headers: {
          'yrh-integration-api-key': key
        }
      })

      const json = await cmsRes.json()
      if (json) {
        setCmsData(json)
      }

      const tenantTable = await generateTable(json)

      setLookup(tenantTable)

      const defaultTenant = new URLSearchParams(window.location.search).get('t')
      const matchingTenant = defaultTenant && tenantTable[`${defaultTenant}`]

      if (matchingTenant) {
        const cmsID = matchingTenant.locationid || matchingTenant.suite.textfields.suitenum

        const floorLookup = { 'Floor 1': 'GroundLevel' }
        const floorName = matchingTenant.floor.name
        const modActiveFloor = floorLookup[floorName]
          ? floorLookup[floorName] 
          : floorName.replace(' ','')

        setViewMode('simple')
        setActiveFloor(modActiveFloor)
        setActiveTenant(`${cmsID}`)
      } else {
        setActiveFloor('GroundLevel')
      }
    }

    if (!cmsData) {
      getTenantData()
    }
  }, [cmsData])

  useEffect(() => {
    const e = window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 768)
    })

    return () => {
      window.removeEventListener('resize', e)
    }
  }, [])

  if (!cmsData || !activeFloor || !lookup) {
    return null
  }

  if (new URLSearchParams(window.location.search).get('t') && !activeTenant) {
    return null
  }

  // removes redundant parent category to ensure category grouping is correct
  cmsData.modTenants = cmsData.tenants
    .map(t => {
      const { category = [] } = t

      let foundCats = []
      let modCategory = []
      
      category.length > 1 && category.forEach(cat => {
        const foundCat = cmsData.categories.find(c => c.label_id === cat)
        foundCats.push(foundCat)
        const { parent = null } = foundCat
        if (parent && foundCats.some(c => c._id === parent)) {
          modCategory.push(foundCat.label_id)
        }
      })

      const modTenant = {
        ...t,
        floor: t.floor || { name: 'Floor 1' }, // set default floor
        category: modCategory.length === 0 ? category : modCategory
      }
      return modTenant
    })

  const list = cmsData.modTenants
    .filter(t => !searchTerm || [t.name].concat(t.category).some(e => e.toLowerCase().includes(searchTerm.toLowerCase())))
    .reduce((acc, curr) => {
      const {
        category
      } = curr

      // set default category
      const c = category[0] || '_general'

      if (!acc[c]) {
        acc[c] = []
      }

      acc[c].push(curr)

      return acc
    }, {})

  const clickHandler = (mesh) => {
    if (viewMode === 'simple') {
      return
    }

    const {
      name
    } = mesh

    const configData = config.shops.find(s => s.assetId === name)

    if (!configData || !configData.cmsID) {
      return
    }

    changeTenant(configData.cmsID)

    if (isMobile) {
      const matchingTenant = cmsData.modTenants.find(t => {
        const {
          locationid = null,
          suite: {
            textfields: {
              suitenum = null
            } = {}
          } = {}
        } = t
        const refID = locationid ? locationid : suitenum
        if (!refID) return
        return `${refID}`.toLowerCase() === `${configData.cmsID}`.toLowerCase()
      })
      if (!matchingTenant) {
        return
      }

      setShowList(true)
      setSearchTerm(matchingTenant.name)
    }

    const listElement = document.querySelector('.tenant-button.active')

    if (!listElement) {
      return
    }

    listElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const amenityIcons = {
    foodcourt: './icons/amenities/foodcourt.svg',
    elevators: './icons/amenities/elevator.svg',
    escalators: './icons/amenities/escalator.svg',
    stairs: './icons/amenities/stairs.svg',
    washrooms: './icons/amenities/washrooms.svg',
    security: './icons/amenities/security.svg'
  }

  if (!cmsData.categories.some(c => c.label_id === '_general')) {
    cmsData.categories.push({
      label_id: '_general',
      name: 'General'
    })
  }

  const subHeading = (id) => {
    const foundCategory = cmsData.categories.find(c => c.label_id === id)
    return foundCategory ? foundCategory.name : 'General'
  }

  return (
    <div className={`App ${viewMode}`}>
      <div className="sidebar">
        <h1>Map</h1>
        <div className="floor-section">
          <h2 className="subheading">View Floor</h2>
          <select value={activeFloor} onChange={(e) => changeFloor(e.target.value)}>
            <option value="PlazaLevel">Plaza Level</option>
            <option value="GroundLevel">Ground Level</option>
          </select>
        </div>
        <div className="search-section">
          <h2 className="subheading">Shop</h2>
          <div className="search-wrapper">
            <input
              type="text"
              value={searchTerm}
              placeholder="Search"
              onChange={(e) => {
                if (activeTenant && activeTenant !== '') setActiveTenant()
                setSearchTerm(e.target.value)
              }}
              onFocus={focusSearch}
              className="shop-search"
            />
          </div>
        </div>
        <div className={`tenant-list ${searchTerm ? 'hide-categories' : ''}`}>
          <button
            className={`tenant-list-button ${showList ? 'up' : 'down'}`}
            onClick={() => setShowList(!showList)}
          >
            {searchTerm ? 'Tenants' : 'Categories'}
          </button>
          <div className={`tenant-list-wrap ${showList ? 'show' : 'hide'}`}>
            {
              Object.entries(list).sort(([idA], [idB]) => idA.localeCompare(idB)).map(([id, tenants]) => (
                <React.Fragment key={id}>
                  <h3 className="category-heading subheading">{ subHeading(id) }</h3>
                  {/* cmsData.categories.find(c => c.label_id === id).name */}
                  <div className="category-listings">
                    {
                      tenants
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(t => {
                          const {
                            locationid = null,
                            suite: {
                              textfields: {
                                suitenum = ''
                                /**
                                 * @IDEA:
                                 * set default suitenum if it doesn't exist in data?
                                 * can show proper "no map" message
                                 * 
                                 *  */ 
                              } = {}
                            } = {},
                            floor: {
                              name: floornum = ''
                            } = {},
                            name,
                            _id
                          } = t
                          const cmsData = lookup[_id]
                          const cmsID = locationid ? locationid : suitenum
                          
                          return (
                            <Fragment key={`${cmsID}-${floornum}-${name}`}>
                              <button
                                className={`tenant-button ${`${activeTenant}` === `${cmsID}` ? 'active' : ''}`}
                                onClick={() => changeTenant(cmsID)}
                              >
                                {name}
                              </button>
                              {`${activeTenant}` === `${cmsID}` && <div className='read-more'>
                                <div>
                                  Floor: {floornum === 'Floor 1' ? 'Ground Level': floornum}
                                </div>
                                {cmsData ? <a rel='noopener noreferrer' href={cmsData.link}>Read more ↗</a> : null}
                              </div>}
                            </Fragment>
                          )
                        })
                    }
                  </div>
                </React.Fragment>
              ))
            }
          </div>
        </div>
      </div>
      {isMobile ? null : <div className="helpIcon">
        <img
          alt="rotation"
          src="./icons/rotation.png"
        />
      </div>}
      <Map3D
        babylonFilepath='/models/jacksonsquare.babylon'
        mapping={{
          config: Config,
          sceneWrapper: SceneWrapper
        }}
        config={config}
        tenantData={cmsData.modTenants}
        icons={amenityIcons}
        options={{
          hideControls: true,
          hideNorthMarker: false,
          showRouteSelector: false,
          clickToHighlight: viewMode === 'full',
          centerActiveTenant: true,
          orthoScale: 0.007,
          upperZoomLimit: isMobile ? 0.04 : 0.015,
          lowerZoomLimit: 0.001, // 0.002,
          cameraBeta: Math.PI / 4,
          disableRotationX: false,
          disableRotationY: false,
          maxRotation: Math.PI / 4,
          minRotation: Math.PI / -4,
          logoKey: 'maplogo',
          labelKey: 'mapname',
          labelFont: 'Mark',
          maxTextLabelSize: 0.015,
          northMarker: './icons/north.svg',
          clickHandler,
          floorLabels: {
            'PlazaLevel': 'Plaza Level',
            'GroundLevel': 'Ground Level'
          },
          zoomSensitivity: {
            mouseWheel: 0.05,
            touch: 0.025
          }
        }}
        activeFloor={activeFloor}
        activeTenant={activeTenant}
        activeAmenities={viewMode === 'full' ? Object.keys(amenityIcons) : []}
        primaryHighlight='#fc4945'
        secondaryHighlight='#fc4945'
        backgroundColor='#555555'
      />
      {
        viewMode === 'full' ? (
          <div className='legend'>
            {
              Object.keys(amenityIcons).map((k, i) => {
                const iconPath = amenityIcons[k]
                return <div id={ k } className='legend-item' key={ i }>
                  <img id={ `${k}-icon` } className='legend-icon' src={ iconPath } alt={ `${k}-icon` } />
                  <span className='legend-label'>{ k.toUpperCase() }</span>
                </div>
              })
            }
          </div>
        ) : <></>
      }    
    </div>
  );
}

export default App;
